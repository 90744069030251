import React, { useState, useRef, useEffect } from "react";

const PostJobAdminForm = () => {
  // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
  const apiEndpoint =
    "https://aimljobs-backend.vercel.app/cognito-forms-webhook";
  // const apiEndpoint = "http://localhost:3001/cognito-forms-webhook";
  const [jobTitle, setJobTitle] = useState("");
  const [team, setTeam] = useState("Engineering"); // Set default value for the dropdown
  const [location, setLocation] = useState(""); // Set default value for the dropdown
  const [locationType, setLocationType] = useState("Remote");
  const [jobType, setJobType] = useState("Fulltime");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [applicationUrl, setApplicationUrl] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [twitterHandle, setTwitterHandle] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [yourName, setYourName] = useState("");
  const [yourEmail, setYourEmail] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const fileInputRef = useRef(null);

  const handleJobTitleChange = (e) => {
    setJobTitle(e.target.value);
  };

  const handleTeamChange = (e) => {
    setTeam(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleLocationTypeChange = (e) => {
    setLocationType(e.target.value);
  };

  const handleJobTypeChange = (e) => {
    setJobType(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleKeywordsChange = (e) => {
    setKeywords(e.target.value);
  };

  const handleApplicationUrlChange = (e) => {
    setApplicationUrl(e.target.value);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);
  };

  const handleTwitterHandleChange = (e) => {
    setTwitterHandle(e.target.value);
  };

  const handleLinkedinLinkChange = (e) => {
    setLinkedinLink(e.target.value);
  };

  const handleYourNameChange = (e) => {
    setYourName(e.target.value);
  };

  const handleYourEmailChange = (e) => {
    setYourEmail(e.target.value);
  };

  const handleChooseFilesButtonClick = () => {
    // Trigger the file input popup
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePaste = async (event) => {
    // Handle pasting from clipboard
    const clipboardData = event.clipboardData || window.clipboardData;
    if (clipboardData) {
      const items = clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.indexOf("image") !== -1) {
          const blob = item.getAsFile();
          // Process the pasted image (blob)
          // You may want to do additional processing with the pasted image, such as displaying it.
          setImage(blob);
        }
      }
    }
  };

  const getImageUrl = async (blob) => {
    let url = "https://i.ibb.co/6bWJH3h/Company-logo.png";
    try {
      const apiKey = process.env.REACT_APP_IMAGE_API_KEY;
      const formData = new FormData();
      formData.append("image", blob);

      const response = await fetch(
        `https://api.imgbb.com/1/upload?key=${apiKey}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const responseData = await response.json();
      url = responseData.data.image.url;
    } catch (error) {
      alert("Error while uploading file", error);
    }
    return url;
  };

  const handleImageChange = async (e) => {
    // Assuming you're dealing with a single image upload
    // const imageUrl = await getImageUrl(e.target.files[0]);
    setImage(e.target.files[0]);
  };

  const handleSubmit = async () => {
    // Create a FormData object to send the form data including the image file
    // const formData = new FormData();
    // formData.append("JobTitle", jobTitle);
    // formData.append("Team", team);
    // formData.append("Address[Country]", location);
    // formData.append("Location", locationType);
    // formData.append("JobType", jobType);
    // formData.append("JobDescription", description);
    // formData.append("Keywords", keywords);
    // formData.append("ApplicationURLrecommendedOrEmailAddress2", applicationUrl);
    // formData.append("CompanyName", companyName);
    // formData.append("CompanyWebsite", website);
    // formData.append("Twitter", twitterHandle);
    // formData.append("Linkedin", linkedinLink);
    // formData.append("YourName", yourName);
    // formData.append("YourCompanyEmail", yourEmail);
    // formData.append("Logo", image);

    const imageUrl = await getImageUrl(image);

    const formData = {
      JobTitle: jobTitle,
      Team: team,
      Address: { Country: location },
      Location: locationType,
      JobType: jobType,
      JobDescription: description,
      Keywords: keywords,
      ApplicationURLrecommendedOrEmailAddress2: applicationUrl,
      CompanyName: companyName,
      CompanyWebsite: website,
      Twitter: twitterHandle,
      Linkedin: linkedinLink,
      YourName: yourName,
      YourCompanyEmail: yourEmail,
      ImageUrl: imageUrl, // You can set the file reference here if needed
    };

    try {
      // Make a POST request to the API
      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        // Handle success, e.g., show a success message
        alert("Form submitted successfully");
      } else {
        // Handle error, e.g., show an error message
        alert("Error: submitting the form", response);
      }
    } catch (error) {
      // Handle network or other errors
      alert("Error:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 container max-w-[60%] ml-40 mt-20 mb-20">
      {/* Row 1, Column 1 - Job Title Input */}
      <div className="p-4">
        <label
          htmlFor="jobTitle"
          className="block text-sm font-medium text-gray-700"
        >
          Job Title
        </label>
        <input
          type="text"
          id="jobTitle"
          name="jobTitle"
          value={jobTitle}
          onChange={handleJobTitleChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      {/* Row 1, Column 2 - Team Dropdown */}
      <div className="p-4">
        <label
          htmlFor="team"
          className="block text-sm font-medium text-gray-700"
        >
          Team
        </label>
        <select
          id="team"
          name="team"
          value={team}
          onChange={handleTeamChange}
          className="mt-1 p-2 w-full border rounded-md"
        >
          {/* Add your dropdown options here */}
          <option value="Engineering">Engineering</option>
          <option value="Operations">Operations</option>
          <option value="Marketing">Marketing</option>
        </select>
      </div>

      <div className="p-4">
        <label
          htmlFor="location"
          className="block text-sm font-medium text-gray-700"
        >
          Location
        </label>
        <input
          type="text"
          id="location"
          name="location"
          value={location}
          onChange={handleLocationChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div className="p-4">
        <label
          htmlFor="Location Type"
          className="block text-sm font-medium text-gray-700"
        >
          Location Type
        </label>
        <select
          id="locationType"
          name="locationType"
          value={locationType}
          onChange={handleLocationTypeChange}
          className="mt-1 p-2 w-full border rounded-md"
        >
          {/* Add your dropdown options here */}
          <option value="Remote">Remote</option>
          <option value="Onsite">Onsite</option>
          <option value="Hybrid">Hybrid</option>
        </select>
      </div>

      <div className="p-4">
        <label
          htmlFor="Job Type"
          className="block text-sm font-medium text-gray-700"
        >
          Job Type
        </label>
        <select
          id="jobType"
          name="job type"
          value={jobType}
          onChange={handleJobTypeChange}
          className="mt-1 p-2 w-full border rounded-md"
        >
          {/* Add your dropdown options here */}
          <option value="team1">Fulltime</option>
          <option value="team2">Part-Time</option>
          <option value="team3">Freelance</option>
        </select>
      </div>

      <div className="col-span-2 p-4">
        <label
          htmlFor="description"
          className="block text-sm font-medium text-gray-700"
        >
          Description
        </label>
        <textarea
          id="description"
          name="description"
          value={description}
          onChange={handleDescriptionChange}
          className="mt-1 p-2 w-full border rounded-md"
          rows="4" // Adjust the number of rows as needed
        ></textarea>

        <a href="https://markdownlivepreview.com/" target="_blank">
          <button className="bg-blue-500 text-white py-2 px-4 rounded-md">
            Format here
          </button>
        </a>
      </div>

      <div className="p-4">
        <label
          htmlFor="keywords"
          className="block text-sm font-medium text-gray-700"
        >
          Keywords
        </label>
        <input
          type="text"
          id="keywords"
          name="keywords"
          value={keywords}
          onChange={handleKeywordsChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      {/* Row 4 - Application URL Input */}
      <div className="p-4">
        <label
          htmlFor="applicationUrl"
          className="block text-sm font-medium text-gray-700"
        >
          Application URL
        </label>
        <input
          type="text"
          id="applicationUrl"
          name="applicationUrl"
          value={applicationUrl}
          onChange={handleApplicationUrlChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      {/* Row 5 - Company Name Input */}
      <div className="p-4">
        <label
          htmlFor="companyName"
          className="block text-sm font-medium text-gray-700"
        >
          Company Name
        </label>
        <input
          type="text"
          id="companyName"
          name="companyName"
          value={companyName}
          onChange={handleCompanyNameChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      {/* Row 6 - Website Input */}
      <div className="p-4">
        <label
          htmlFor="website"
          className="block text-sm font-medium text-gray-700"
        >
          Website
        </label>
        <input
          type="text"
          id="website"
          name="website"
          value={website}
          onChange={handleWebsiteChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      {/* Row 7 - Twitter Handle Input */}
      <div className="p-4">
        <label
          htmlFor="twitterHandle"
          className="block text-sm font-medium text-gray-700"
        >
          Twitter Handle
        </label>
        <input
          type="text"
          id="twitterHandle"
          name="twitterHandle"
          value={twitterHandle}
          onChange={handleTwitterHandleChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      {/* Row 8 - LinkedIn Link Input */}
      <div className="p-4">
        <label
          htmlFor="linkedinLink"
          className="block text-sm font-medium text-gray-700"
        >
          LinkedIn Link
        </label>
        <input
          type="text"
          id="linkedinLink"
          name="linkedinLink"
          value={linkedinLink}
          onChange={handleLinkedinLinkChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      {/* Row 9 - Your Name Input */}
      <div className="p-4">
        <label
          htmlFor="yourName"
          className="block text-sm font-medium text-gray-700"
        >
          Your Name
        </label>
        <input
          type="text"
          id="yourName"
          name="yourName"
          value={yourName}
          onChange={handleYourNameChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      {/* Row 10 - Your Email Input */}
      <div className="p-4">
        <label
          htmlFor="yourEmail"
          className="block text-sm font-medium text-gray-700"
        >
          Your Email
        </label>
        <input
          type="email"
          id="yourEmail"
          name="yourEmail"
          value={yourEmail}
          onChange={handleYourEmailChange}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      <div className="col-span-2 p-4 bg-gray-100" onPaste={handlePaste}>
        <label className="block text-sm font-medium text-gray-700">Logo</label>
        <input
          type="file"
          id="image"
          name="image"
          onChange={handleImageChange}
          className="hidden" // Hide the file input
          ref={fileInputRef}
        />
        <button
          onClick={handleChooseFilesButtonClick}
          className="mt-1 p-2 border rounded-md"
        >
          Choose Files
        </button>
        <div className="mt-2">
          {/* Optionally display the pasted image */}
          {image && (
            <>
              <img
                src={URL.createObjectURL(image)}
                alt="Pasted"
                className="max-w-full h-auto"
              />
              <button
                className="bg-red-500 text-white mt-5 py-2 px-4 rounded-m"
                onClick={() => setImage(null)}
              >
                Remove
              </button>
            </>
          )}
        </div>
      </div>

      {/* Row 12 - Submit Button */}
      <div className="col-span-2 p-4">
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-md"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default PostJobAdminForm;

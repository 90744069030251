import React, { useEffect } from "react";

const apiEndpoint = "https://aimljobs-backend.vercel.app/cognito-forms-webhook";

const JobFeeder = () => {
  const getDataFromFeeder = async () => {
    const url =
      "https://jsearch.p.sulu.sh/v1/search?date_posted=today&query=software+developer+in+AI+startup+in+USA";
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_SULU_AUTH_KEY}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      const jobs = data.data;
      console.log(jobs);
      jobs.forEach(async (job) => {
        const formData = {
          JobTitle: job.job_title || "",
          Team: "Engineering",
          Address: { Country: job.job_country || "" },
          Location: "Remote",
          JobType: "Fulltime",
          JobDescription: job.job_description || "",
          Keywords: ["AI", "ML"].join(", "),
          ApplicationURLrecommendedOrEmailAddress2: job.job_apply_link,
          CompanyName: job.employer_name,
          CompanyWebsite: job.employer_website || "",
          Twitter: "",
          Linkedin: "",
          YourName: "AI Jobs",
          YourCompanyEmail: "",
          ImageUrl: job.employer_logo || "",
        };

        await fetch(apiEndpoint, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setInterval(() => {
      getDataFromFeeder();
    }, 24 * 60 * 60 * 1000);
  }, []);

  return (
    <div>
      <h1>JobFeeder</h1>
    </div>
  );
};

export default JobFeeder;

import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { LandingPage } from "./pages/LandingPage";
import { About } from "./components/About";
import { JobDescriptionPage } from "./pages/JobDescriptionPage";
import { PostJobPage } from "./pages/PostJobPage";
import PostJobAdminForm from "./components/PostJobAdminForm";
import Banner from "./components/Banner";
import JobFeeder from "./components/JobFeeder";

function App() {
  return (
    <Router>
      {/* <Banner /> */}
      <Routes>
        <Route path="/" element={<LandingPage admin={false} />} />
        <Route path="/jobs-admin" element={<LandingPage admin={true} />} />
        <Route path="/job/:id" element={<JobDescriptionPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/post-job" element={<PostJobPage />} />
        <Route path="/post-job-admin" element={<PostJobAdminForm />} />
        <Route path="/job-feeder" element={<JobFeeder />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from "react";

export const PostJobFormNoCode = () => {
  return (
    <>
      <div className="p-10">
        <iframe
          id="form"
          src="https://www.cognitoforms.com/f/971y9DdIZkKtn0gX5Qu1yQ/1"
          style={{
            marginTop: "5%",
            border: 0,
            width: "100%",
          }}
          height="933"
        ></iframe>
      </div>
    </>
  );
};

import React from "react";
import { Header } from "../components/Header";
import { LandingContainer } from "../components/LandingContainer";
import { JobList } from "../components/JobList";
import { SearchContainer } from "../components/SearchContainer";
import { Footer } from "../components/Footer";
import SocialIcons from "../components/SocialIcons";
import SubscriptionForm from "../components/SubscriptionForm";
import { InstantSearch, Configure } from "react-instantsearch";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite.css";
import AIPH_Banner from "../sponsors/AIPH";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

export const LandingPage = ({ admin }) => {
  return (
    <>
      <Header />

      <div className="min-h-screen">
        <LandingContainer />
        <div id="findjob">
          <div className="lg:max-w-5xl mx-auto mt-16 md:mt-24 md:px-6 xl:px-0 search-ui">
            <InstantSearch searchClient={searchClient} indexName="jobs_index">
              <Configure hitsPerPage={1000} />
              <SearchContainer />
              <JobList admin={admin} />
              <AIPH_Banner />
              <SubscriptionForm />
            </InstantSearch>
          </div>
        </div>
      </div>
      <SocialIcons />
      <Footer />
    </>
  );
};

import React from "react";

export const Header = () => {
  return (
    <header id="header-nav" className="bg-gray-900 py-5">
      <div className="lg:max-w-6xl mx-auto px-4 xl:px-0">
        <div className="flex justify-between items-center">
          <a href="/" aria-label="Homepage" className="flex items-center">
            <img
              id="logo"
              className="h-10 w-10"
              src="/assets/images/ai-logo.png"
              alt="AI/ML Jobs"
            />
          </a>
          <div
            id="navigation"
            className="flex items-center space-x-3 md:space-x-6 "
          >
            <nav className="sm:flex items-center text-lg">
              <a
                className="text-white font-medium hover:opacity-75 transition-opacity"
                href="/"
              >
                Jobs
              </a>
              {/* <a
                className="text-white font-medium ml-2 md:ml-4 hover:opacity-75 transition-opacity"
                href="/about"
              >
                About
              </a> */}
            </nav>
            <div className="block text-center">
              <a
                className="block items-center text-md text-base text-white font-medium bg-white bg-opacity-20 hover:bg-opacity-30 border border-transparent pb-1.5 pl-2 pr-2 pt-1 rounded-md group hover:bg-gray-200 transition-colors"
                href="/post-job/"
              >
                Post job
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

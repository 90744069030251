import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { JobDescription } from "../components/JobDescription";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import SocialIcons from "../components/SocialIcons";
import SubscriptionForm from "../components/SubscriptionForm";

// const jobData = {
//   title: "(Junior) Risk Controller",
//   company: "Finoa",
//   description:
//     "Finoa is looking to hire a (Junior) Risk Controller to join their team...",
//   locations: ["Germany", "Porto", "Remote"],
//   jobType: "Full-Time",
//   role: "Operations",
//   keywords: ["Junior", "Risk Management", "Web3"],
//   applyLink:
//     "https://finoa.jobs.personio.com/job/1339725?ref=cryptocurrencyjobs.co",
//   shareLinks: {
//     facebook:
//       "https://www.facebook.com/sharer/sharer.php?u=https%3a%2f%2fcryptocurrencyjobs.co%2foperations%2ffinoa-junior-risk-controller%2f",
//     twitter:
//       "https://twitter.com/intent/tweet/?text=Finoa%20is%20hiring%20a%20remote%20%28Junior%29%20Risk%20Controller&amp;url=https%3a%2f%2fcryptocurrencyjobs.co%2foperations%2ffinoa-junior-risk-controller%2f&amp;via=jobsincrypto",
//     linkedin:
//       "https://www.linkedin.com/shareArticle?mini=true&amp;source=Cryptocurrency%20Jobs&amp;title=%28Junior%29%20Risk%20Controller&amp;url=https%3a%2f%2fcryptocurrencyjobs.co%2foperations%2ffinoa-junior-risk-controller%2f",
//     email:
//       "mailto:?body=Check%20it%20out:%20https%3a%2f%2fcryptocurrencyjobs.co%2foperations%2ffinoa-junior-risk-controller%2f%20via%20Cryptocurrency%20Jobs&amp;subject=Finoa%20is%20hiring%20a%20remote%20%28Junior%29%20Risk%20Controller",
//   },
//   aboutCompanyLink: "/startups/finoa/",
//   // Add other necessary properties here based on your data structure
// };

export const JobDescriptionPage = () => {
  const [jobData, setJobData] = useState(null);
  const { id: jobId } = useParams();

  const getJobDescription = async () => {
    try {
      const response = await fetch(
        `https://aimljobs-backend.vercel.app/jobs/${jobId}`
      );
      const data = await response.json();
      setJobData(data);
    } catch (error) {
      alert("Network issue");
    }
  };

  useEffect(() => {
    getJobDescription();
  }, []);

  return (
    <>
      <Header />
      <div className="min-h-screen">
        <JobDescription jobData={jobData} />
      </div>
      <SubscriptionForm />
      <SocialIcons />
      <Footer />
    </>
  );
};

import React from "react";

export const Footer = () => {
  return (
    <footer className="w-full text-gray-10 mt-2">
      <div className="container mx-auto py-8 px-4">
        <div className="text-center">
          <a
            href="https://forms.gle/gxybCdQTxZSdhCRS7"
            className="text-purple font-bold"
            target="_blank"
          >
            Feedback Form
          </a>
          <h1 className="font-medium">Support: aimljobhub@gmail.com</h1>
          <p>&copy; 2024 aimljobs.fyi All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

import React from "react";
import { Header } from "./Header";

export const About = () => {
  return (
    <>
      <Header />
    </>
  );
};

import React from "react";
import { Header } from "../components/Header";
import { PackDetails } from "../components/PackDetails";
import { PostJobFormNoCode } from "../components/PostJobFormNoCode";

export const PostJobPage = () => {
  return (
    <>
      <Header />
      <PackDetails />
      <PostJobFormNoCode />
    </>
  );
};

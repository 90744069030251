import React from "react";

import { SearchBox } from "react-instantsearch";

export const SearchContainer = () => {
  return (
    <>
      <div className="ais-InstantSearch">
        <SearchBox
          classNames={{
            input: "pt-5 pb-5 md:p-5 bg-white",
            form: "items-center",
          }}
          placeholder="Search by role, company, location, anything..."
          className="mb-10 md:mb-14 ml-2 mr-2"
        />
      </div>
    </>
  );
};

import React, { useState } from "react";

const SubscriptionForm = () => {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscription = async () => {
    if (!isValidEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const apiEndpoint = "https://aimljobs-backend.vercel.app/subscribe";
    // const apiEndpoint = "http://localhost:3001/subscribe";

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify({ email: email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        alert("Thanks for subscribing!");
        setEmail("");
      } else {
        // Handle error, e.g., show an error message
        alert("Error: submitting the form, ", response);
      }
    } catch (e) {
      alert("Error: ", e);
    }
    // Your subscription logic here
    console.log("Subscribed:", email);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="max-w-md mx-auto flex-col justify-center text-center mb-20 mt-20">
      <h1 className="text-lg md:text-2xl font-bold mb-4">
        Subscribe to receive the latest jobs!
      </h1>
      <div className="flex items-center">
        <input
          value={email}
          onChange={handleInputChange}
          type="email"
          placeholder="Enter your email"
          className="w-full ml-2 md:w-3/4 p-2 md:p-3 border border-gray-300 rounded focus:outline-none"
          required
        />
        <button
          onClick={handleSubscription}
          className="w-1/4 text-white text-sm md:text-base lg:text-lg font-semibold ml-2 mr-2 bg-purple border-transparent px-2 md:px-5 py-2.5 rounded-md hover:bg-purple-dark hover:border-purple-dark transition-colors"
        >
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default SubscriptionForm;
